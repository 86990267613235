import React from 'react';
import './Brand.scss';

export const Brand = () => (
  <div className='brand'>
    <span>
    <span>Aves</span>
    <span>LOG</span>
    </span>
  </div>
);
